import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// Redux
import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";
import { appReducer } from "./app-reducer";
import { timelineReducer } from "./general-components/content-base/paragraphs/timeline/reducer-timeline";
// import { calendarMonthReducer } from "./general-components/content-base/paragraphs/calendar-month/reducer-calendar-month";
import { adminAppAction } from "./app-actions";
import i18nReducer from "./i18n/i18n-slice";

// GraphQL
import { restHost, graphQlEndpoint } from "./config";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import possibleTypes from "./../../public/data/possibleTypes.json";

import App from "./app";
import { getCurrentLanguage } from "./lib/lib";

const store = createStore(
    combineReducers({
      appStore: appReducer,
      timeLine: timelineReducer,
      // calendarMonth: calendarMonthReducer,
      i18n: i18nReducer,
    }),
    window.__PRELOADED_STATE__
  ),
  errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }),
  client = new ApolloClient({
    link: from([
      errorLink,
      createHttpLink({
        uri: () => {
          const currentLanguage = getCurrentLanguage(window.location.pathname);

          return `${restHost}${
            currentLanguage !== "und" ? "/" + currentLanguage : ""
          }${graphQlEndpoint}`;
        },
        credentials: "same-origin",
      }),
    ]),
    cache: new InMemoryCache({ possibleTypes }),
  });

// Allow the passed states to be garbage-collected
delete window.__PRELOADED_STATE__;

store.dispatch(adminAppAction(true));

window.onload = () => {
  /**
   * Full Page Slider on Frontpage.
   * @type {NodeListOf<Element>}
   */
  const adminApp = document.querySelector("#adminApp");

  if (adminApp.innerHTML === "") {
    const root = createRoot(adminApp);
    root.render(
      <ApolloProvider client={client}>
        <Provider store={store}>
          <BrowserRouter forceRefresh={true}>
            <App />
          </BrowserRouter>
        </Provider>
      </ApolloProvider>
    );
  }
};
